.card {
  width: 200px;
  height: 250px;
  overflow: hidden !important;
  display: block;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
}
.card .content {
  text-align: center;
  padding: 10px;
  opacity: 1;
  transition: all 500ms ease-in-out;
  transform: translate(0, 107px);
  padding: 100px 0px 0px 0px;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.356);
}
.card .content h1 {
  font-size: 15px;
  line-height: 15px;
  color: white;
  background-color: transparent;
  font-weight: bold;
  display: block;
}
.card .content p {
  padding-bottom: 18px;
  padding-top: 5px;
  font-size: 13px;
  line-height: 13px;
  color: white;
  margin-bottom: 2px;
}
.card .content .btn {
  background-color: rgb(39, 39, 39);
  color: white;
  padding: 10px;
  text-decoration: none;
}
.card .content:hover {
  opacity: 1;
  transform: translate(0, 0px);
}
