.grid {
  display: grid;
  margin-left: 4rem;
  margin-right: 4rem;
  margin-top: 20px;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
}

@media (min-width: 768px) and (max-width: 1160px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 425px) and (max-width: 767px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
